import React, { memo } from 'react';
import { updatePostalCode } from './PostalFieldsFunctions';
import { useAddressFormatContext } from '../../../AddressFormatSwitcher/AddressFormatSwitcher';
import { formatMessage } from '../../../../../../Localization/formatMessage';
import Messages from '../../../../../../Localization/Messages';

const PostalFields = () => {
  const {
    addressData,
    setAddressData,
    getCommonFields,
  } = useAddressFormatContext();
  return (
    <div className="relax-row">
      <div className="relax-col-3--xs">
        <label>{formatMessage(Messages.addressComponentPostalCodeLabel)}</label>
        <input
          className="max-width"
          {...getCommonFields('postalCode')}
          onBlur={e => {
            updatePostalCode(addressData, setAddressData, e.target.value);
          }}
          type="text"
          placeholder="Required"
        />
      </div>
      <div className="relax-col-5--xs">
        <label>{formatMessage(Messages.addressComponentPostalCityLabel)}</label>
        <input
          className="max-width"
          id="city"
          type="text"
          placeholder="Required"
          {...getCommonFields('city')}
        />
      </div>
    </div>
  );
};

export default memo(PostalFields);
