import React, { useEffect, useReducer, useState } from 'react';
import {
  Loader,
  SecondaryButton,
  PrimaryButton,
  Label,
} from '@customer/react-relax';
import './EditBrokerSalesCode.css';
import { ServiceClient } from '../../Services/ServiceClient';
import Messages from '../../Localization/Messages';
import { formatMessage } from '../../Localization/formatMessage';
import { PresentationMode } from '../../Enums/PresentationMode';
import InputElement from '../../Elements/InputElement';
import CheckboxElement from '../../Elements/CheckboxElement';
import { BrokerCostType } from '../../Enums/BrokerCostType';
import UpdatedByItem from '../../Elements/UpdatedByItem';
import { Notification } from '../../Elements/Notification';
import { NotificationType } from '../../Enums/NotificationType';
import CustomerDropdown from '../CustomerDropdown/CustomerDropdown';
import { ResultErrorCode } from '../../Enums/ResultErrorCode';
import { BrokerCostExceptionSeverity } from '../../Enums/BrokerCostExceptionSeverity';

const EditBrokerSalesCode = ({
  salesCode = null,
  customerNumber = null,
  presentationMode = PresentationMode.View,
  allowChangeCustomer = false,
  isNewRate = false,
  intialCustomersList = [],
  excludedCustomerNumbers = [],
  onSave = rate => {},
  onCancel = () => {},
  onCustomerSelect = c => {},
}) => {
  const defaultState = {
    salesCode: null,
    customerNumber: null,
    useBrokerCost: false,
    usePersonalCommission: false,
    useMotorCommission: false,
    inheritCommissionHandling: null,
    effective: new Date(),
    expires: new Date(2099, 12, 31),
    updated: new Date(),
    updatedBy: null,
    mappedToIfLogin: false,
    rates: [],
  };

  const staticRates = [
    { rateType: BrokerCostType.PersonalCommission, rate: 10 },
  ];

  const [isValid, setIsValid] = useState(false);
  const [isCustomerValid, setIsCustomerValid] = useState(false);
  const [isPristine, setIsPristine] = useState(true);
  const [showRates, setShowRates] = useState(true);
  const [initialRates, setInitialRates] = useState({ ...defaultState });
  const [state, setFullState] = useState({ ...defaultState });
  const [selectedCustomerNumber, setSelectedCustomerNumber] = useState(null);
  const [customersList, setCustomersList] = useState([
    {
      customerNumber: undefined,
      displayedCustomerNumber: formatMessage(
        Messages.brokerCostRatesModalNoCustomer
      ),
      displayedCustomerName: null,
      presentInitially: true,
    },
  ]);
  const [notificationMessage, setNotificationMessage] = useState({
    message: null,
    type: NotificationType.Warning,
  });

  // loading counter in a case of multiple requests. If loadingCounter>0, loading will be shown
  const [loadingCounter, dispatchLoading] = useReducer((state, modifier) => {
    if (modifier === null) return 0;
    return state + modifier;
  }, 0);
  function addLoading() {
    dispatchLoading(1);
  }
  function subLoading() {
    dispatchLoading(-1);
  }
  function resetLoading() {
    dispatchLoading(null);
  }

  const setState = (stateChange, updatePristine = true) => {
    if (!stateChange) return;
    // modify only psoperties, already existing in state and do not add new ones
    const processedStateChange = {};
    Object.keys(stateChange)
      .filter(
        key =>
          key in state &&
          stateChange[key] !== state[key] &&
          JSON.stringify(stateChange[key]) !== JSON.stringify(state[key])
      )
      .forEach(key => (processedStateChange[key] = stateChange[key]));

    if (Object.keys(processedStateChange).length === 0) {
      return;
    }

    if (updatePristine) {
      setIsPristine(false);
    }
    setFullState({ ...state, ...processedStateChange });
  };

  // On initialization / params change
  useEffect(() => {
    loadCustomerData(customerNumber, true);
    setSelectedCustomerNumber(customerNumber);

    return () => {
      setState(defaultState);
      resetLoading();
      setCustomersList([customersList[0]]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesCode, customerNumber]);

  useEffect(() => {
    if (isNewRate) {
      setNotificationMessage({
        message: formatMessage(
          Messages.brokerCostRatesModalCustomerHasNoSpecificRates,
          {
            customerNumber: selectedCustomerNumber,
          }
        ),
        type: NotificationType.Info,
      });
    } else {
      setNotificationMessage(null);
    }
  }, [isNewRate, selectedCustomerNumber]);

  // on selectedCustomerNumber change
  useEffect(() => {
    loadCustomerData(selectedCustomerNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomerNumber]);

  // on state change
  useEffect(() => {
    var valid = state.rates.every(x => x.rate >= 0 && x.rate <= 100);
    setIsValid(valid);
    setShowRates(!state.inheritCommissionHandling);

    var isCustomerValid =
      (allowChangeCustomer &&
        !excludedCustomerNumbers.includes(
          selectedCustomerNumber ?? undefined
        )) ||
      (!allowChangeCustomer && selectedCustomerNumber === customerNumber);
    setIsCustomerValid(isCustomerValid);
  }, [
    state,
    customerNumber,
    allowChangeCustomer,
    selectedCustomerNumber,
    excludedCustomerNumbers,
  ]);

  function convertDateTimeToInput(value) {
    if (!value) {
      return null;
    }
    if (value instanceof Date) {
      value = value.toISOString();
    }
    return value.substr(0, value.lastIndexOf(':'));
  }

  function loadCustomerData(customerNumberToLoad, initialLoading = false) {
    if (
      !initialLoading &&
      customerNumberToLoad === state.customerNumber &&
      isPristine
    ) {
      return;
    }
    if (
      !initialLoading &&
      !allowChangeCustomer &&
      customerNumberToLoad !== customerNumber
    ) {
      return;
    }

    addLoading();
    ServiceClient.getBrokerCostRates(
      salesCode,
      customerNumberToLoad,
      false,
      response => {
        let stateUpdate = null;
        if (response) {
          response = { ...response, salesCode: response?.agentCode };
          stateUpdate = { ...response };
          if (!customerNumberToLoad) {
            stateUpdate.customerNumber = customerNumberToLoad;
          }
          if (!response?.customerNumber && customerNumberToLoad) {
            stateUpdate.updatedBy = null;
            stateUpdate.inheritCommissionHandling = true;
            stateUpdate.customerNumber = customerNumberToLoad;
          }

          setState(stateUpdate, false);
          setInitialRates(stateUpdate);
          // if customer is changeable, then after loading data make it possible to save without changes
          setIsPristine(!allowChangeCustomer);
        } else {
          stateUpdate = { ...defaultState };
          stateUpdate.salesCode = salesCode;
          setState(stateUpdate, false);
          setInitialRates(defaultState);
          setIsPristine(true);
        }

        subLoading();
      },
      () => {
        subLoading();
      }
    );
  }

  // when Cancel button clicked
  function onCancelClick() {
    onCancel();
  }

  // when Save button clicked
  function onSaveClick() {
    var result = {
      salesCode: state.salesCode,
      customerNumber: state.customerNumber,
      useBrokerCost: state.useBrokerCost,
      usePersonalCommission: state.usePersonalCommission,
      useMotorCommission: state.useMotorCommission,
      inheritCommissionHandling: state.inheritCommissionHandling,
      rates: getRatesConsideringFlags(state, initialRates, false),
    };

    addLoading();

    if (result.customerNumber) {
      ServiceClient.setBrokerCostRatesForCustomer(
        result.salesCode,
        result.customerNumber,
        result,
        data => {
          setNotificationMessage({
            message: formatMessage(
              Messages.brokerCostRatesModalRatesSuccessfullySaved
            ),
            type: NotificationType.Success,
          });
          subLoading();

          result.customerNumber = result.customerNumber ?? undefined;
          result.updatedBy = {
            email: null,
            isDefault: false,
            sourceSystem: 2,
            userIdentifier: 'You',
          };
          result.needReload = true;

          onSave(result);
        },
        (error, errorData) => {
          if (
            errorData.code === ResultErrorCode.BusinessException.toString() &&
            errorData.argument
          ) {
            var notificationType =
              errorData.argument.severity ===
              BrokerCostExceptionSeverity.Warning
                ? NotificationType.Warning
                : NotificationType.Error;
            setNotificationMessage({
              message: errorData.message,
              type: notificationType,
            });
            setIsPristine(true);
          } else {
            setNotificationMessage({
              message: error,
              type: NotificationType.Error,
            });
          }
          subLoading();
          return true;
        }
      );
    } else {
      ServiceClient.setBrokerCostRates(
        result.salesCode,
        result,
        () => {
          setNotificationMessage({
            message: formatMessage(
              Messages.brokerCostRatesModalRatesSuccessfullySaved
            ),
            type: NotificationType.Success,
          });
          subLoading();

          result.customerNumber = result.customerNumber ?? undefined;
          result.updatedBy = {
            email: null,
            isDefault: false,
            sourceSystem: 2,
            userIdentifier: 'You',
          };
          result.needReload = true;

          onSave(result);
        },
        (error, errorData) => {
          if (
            errorData.code === ResultErrorCode.BusinessException.toString() &&
            errorData.argument
          ) {
            var notificationType =
              errorData.argument.severity ===
              BrokerCostExceptionSeverity.Warning
                ? NotificationType.Warning
                : NotificationType.Error;
            setNotificationMessage({
              message: errorData.message,
              type: notificationType,
            });
            setIsPristine(true);
          } else {
            setNotificationMessage({
              message: error,
              type: NotificationType.Error,
            });
          }
          subLoading();
          return true;
        }
      );
    }
  }

  // function takes rates from current rates or from previous rates, depending on flags
  function getRatesConsideringFlags(
    ratesObj,
    initialRatesObj,
    includeEditableFlag
  ) {
    var rateRules = [
      {
        rates: [
          BrokerCostType.Property,
          BrokerCostType.Liability,
          BrokerCostType.Person,
          BrokerCostType.Transport,
          BrokerCostType.Casco,
        ],
        isEditable:
          ratesObj.useBrokerCost && !ratesObj.inheritCommissionHandling,
      },
      {
        rates: [BrokerCostType.MotorCommission],
        isEditable:
          ratesObj.useMotorCommission && !ratesObj.inheritCommissionHandling,
      },
      {
        rates: [BrokerCostType.PersonalCommission],
        isEditable:
          ratesObj.usePersonalCommission && !ratesObj.inheritCommissionHandling,
      },
    ];

    var rates = rateRules
      .map(m =>
        m.rates.map(rt => {
          const staticRate = m.isEditable
            ? staticRates.find(sr => sr.rateType === rt)
            : null;
          let rate = staticRate ??
            (m.isEditable ? ratesObj : initialRatesObj)?.rates?.find(
              x =>
                x.rateType === rt && !staticRates.some(sr => sr.rateType === rt)
            ) ?? {
              rateType: rt,
              rate: 0,
            };
          return includeEditableFlag
            ? { ...rate, editable: m.isEditable }
            : rate;
        })
      )
      .flat();
    return rates;
  }

  function makeRateInputFields(commissionTypes) {
    var rates = getRatesConsideringFlags(state, initialRates, true);
    return (
      <>
        {commissionTypes
          .map(type => rates.find(x => x && x.rateType === type))
          .map(rate => (
            <InputElement
              key={rate?.id}
              name={'rate_' + rate.rateType}
              value={rate.rate}
              readonly={
                presentationMode === PresentationMode.View ||
                !rate.editable ||
                staticRates.some(sr => sr.rateType === rate.rateType)
              }
              forceValueUpdate={true}
              onBlur={e => {
                var newRates = state.rates.map(x => ({ ...x }));
                var existingRate = newRates.find(
                  x => x.rateType === rate.rateType
                );
                var rateVal = parseFloat(e.target.value) || 0;
                if (existingRate) {
                  existingRate.rate = rateVal;
                } else {
                  newRates.push({ rateType: rate.rateType, rate: rateVal });
                }

                setState({ rates: newRates });
              }}
              title={BrokerCostType.valueToString(
                rate.rateType
              )}></InputElement>
          ))}
      </>
    );
  }

  return (
    <>
      {!!loadingCounter && <Loader />}
      {!loadingCounter && (
        <div className="bsc-container">
          {!!notificationMessage?.message && (
            <Notification
              type={notificationMessage.type}
              hideCloseButton={true}
              autoClose={false}>
              {notificationMessage.message}
            </Notification>
          )}
          {allowChangeCustomer && (
            <div className="bsc-customer-dropdown-container">
              <Label className="bsc-customer-dropdown-label">
                {formatMessage(Messages.brokerCostRatesModalDropdownHeader)}
              </Label>
              <CustomerDropdown
                customers={intialCustomersList}
                useAddButton={false}
                showHeader={false}
                searchOnServer={true}
                excludedCustomerNumbers={excludedCustomerNumbers}
                selectedCustomerNumber={selectedCustomerNumber}
                onCustomerSelect={c => {
                  setSelectedCustomerNumber(c?.customerNumber ?? null);
                  onCustomerSelect(c);
                }}
              />
            </div>
          )}
          <div className="bsc-columns-container bsc-columns-outer-container">
            <div className="bsc-column">
              <h3>{formatMessage(Messages.brokerCostRatesModalInfoHeader)}</h3>

              <div className="bsc-columns-container">
                <div className="bsc-column">
                  <InputElement
                    name={'effective'}
                    value={convertDateTimeToInput(state.effective)}
                    readonly={true}
                    type={'datetime-local'}
                    title={formatMessage(
                      Messages.brokerCostRatesModalEffective
                    )}></InputElement>
                  <InputElement
                    name={'expires'}
                    value={convertDateTimeToInput(state.expires)}
                    readonly={true}
                    type={'datetime-local'}
                    title={formatMessage(
                      Messages.brokerCostRatesModalExpires
                    )}></InputElement>
                  <InputElement
                    name={'updated'}
                    value={convertDateTimeToInput(state.updated)}
                    readonly={true}
                    type={'datetime-local'}
                    title={formatMessage(
                      Messages.brokerCostRatesModalUpdated
                    )}></InputElement>
                  <div class="relax-form-group">
                    <label>
                      {formatMessage(Messages.brokerCostRatesModalUpdatedBy)}
                    </label>
                    <div>
                      <UpdatedByItem updatedBy={state.updatedBy} />
                    </div>
                  </div>
                  <CheckboxElement
                    name={'mappedToIfLogin'}
                    checked={state.mappedToIfLogin}
                    readonly={true}
                    title={formatMessage(
                      Messages.brokerCostRatesModalMappedToIfLogin
                    )}
                  />
                </div>

                <div className="bsc-column bsc-column-no-header relax-form-group">
                  {!!selectedCustomerNumber && (
                    <CheckboxElement
                      name={'inheritCommissionHandling'}
                      checked={state.inheritCommissionHandling}
                      onChangeValue={x =>
                        setState({ inheritCommissionHandling: x })
                      }
                      readonly={presentationMode === PresentationMode.View}
                      title={formatMessage(
                        Messages.brokerCostRatesModalInheritCommissionHandling
                      )}
                    />
                  )}
                  <CheckboxElement
                    name={'useBrokerCost'}
                    checked={state.useBrokerCost}
                    onChangeValue={x => setState({ useBrokerCost: x })}
                    readonly={presentationMode === PresentationMode.View}
                    title={formatMessage(
                      Messages.brokerCostRatesModalUseBrokerCost
                    )}
                  />
                  <CheckboxElement
                    name={'usePersonalCommission'}
                    checked={state.usePersonalCommission}
                    onChangeValue={x => {
                      setState({ usePersonalCommission: x });
                    }}
                    readonly={presentationMode === PresentationMode.View}
                    title={formatMessage(
                      Messages.brokerCostRatesModalUsePersonalCommission
                    )}
                  />
                  <CheckboxElement
                    name={'useMotorCommission'}
                    checked={state.useMotorCommission}
                    onChangeValue={x => setState({ useMotorCommission: x })}
                    readonly={presentationMode === PresentationMode.View}
                    title={formatMessage(
                      Messages.brokerCostRatesModalUseMotorCommission
                    )}
                  />
                </div>
              </div>
            </div>

            {showRates && (
              <>
                <div className="bsc-column">
                  <h3>
                    {formatMessage(Messages.brokerCostRatesModalRatesHeader)}
                  </h3>
                  {makeRateInputFields([
                    BrokerCostType.Property,
                    BrokerCostType.Liability,
                    BrokerCostType.Person,
                    BrokerCostType.Transport,
                    BrokerCostType.Casco,
                  ])}
                </div>
                <div className="bsc-column">
                  <h3>
                    {formatMessage(
                      Messages.brokerCostRatesModalCommissionHeader
                    )}
                  </h3>
                  {makeRateInputFields([
                    BrokerCostType.PersonalCommission,
                    BrokerCostType.MotorCommission,
                  ])}
                </div>
              </>
            )}
          </div>
          <div className="bsc-buttons-container">
            <SecondaryButton onClick={onCancelClick}>
              {formatMessage(Messages.cancel)}
            </SecondaryButton>
            <PrimaryButton
              onClick={onSaveClick}
              disabled={!isValid || !isCustomerValid || isPristine}>
              {formatMessage(Messages.save)}
            </PrimaryButton>
          </div>
        </div>
      )}
    </>
  );
};

export default EditBrokerSalesCode;
