import React, { memo } from 'react';
import './StreetAddress.css';
import {
  COUNTRY_CODE_DENMARK,
  COUNTRY_CODE_GREENLAND,
  COUNTRY_CODE_SWEDEN,
  addressActions,
} from '../../../AddressConstants';
import AddressSearch from '../CommonFields/AddressSearch';
import CountrySelector from '../CommonFields/CountrySelector';
import { useGlobalAddressContext } from '../../../AddressProvider';
import PostalFields from '../CommonFields/PostalFields';
import { useAddressFormatContext } from '../../AddressFormatSwitcher';
import CareOfFields from '../CommonFields/CareOfFields/';
import { formatMessage } from '../../../../../Localization/formatMessage';
import Messages from '../../../../../Localization/Messages';

const StreetAddress = () => {
  const { addressData, getCommonFields } = useAddressFormatContext();
  const { addressAction } = useGlobalAddressContext();

  return (
    <>
      {addressAction === addressActions.Create && <AddressSearch />}

      <CareOfFields />

      <div className="relax-row">
        <div className="relax-col-8--xs">
          <label>{formatMessage(Messages.addressComponentStreetName)}</label>
          <input
            className="max-width"
            type="text"
            placeholder="Required"
            {...getCommonFields('streetName')}
          />
        </div>
        <div className="relax-col-2--xs">
          <label>
            {formatMessage(Messages.addressComponentStreetHouseNumber)}
          </label>
          <input type="text" {...getCommonFields('houseNumber')} />
        </div>
        <div className="relax-col-2--xs">
          <label>
            {formatMessage(Messages.addressComponentStreetHouseLetter)}
          </label>
          <input type="text" {...getCommonFields('houseLetter')} />
        </div>
      </div>

      {[
        COUNTRY_CODE_SWEDEN,
        COUNTRY_CODE_DENMARK,
        COUNTRY_CODE_GREENLAND,
      ].includes(addressData.country?.code) && (
        <>
          <label>
            {formatMessage(Messages.addressComponentStreetApartmentNumber)}
          </label>
          <input
            className="apartment-number"
            type="text"
            {...getCommonFields('apartmentNumber')}
          />
        </>
      )}

      <label>
        {formatMessage(Messages.addressComponentStreetAdditionalLine)}
      </label>
      <input
        className="additional-address-line"
        type="text"
        {...getCommonFields('additionalAddress')}
      />

      <CountrySelector />
      <PostalFields />
    </>
  );
};

export default memo(StreetAddress);
