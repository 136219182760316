import React, { useEffect, useReducer, useState } from 'react';
import './CommissionPage.css';
import Messages from '../Localization/Messages';
import { formatMessage } from '../Localization/formatMessage';
import { ServiceClient } from '../Services/ServiceClient';
import { BrokerCostType } from '../Enums/BrokerCostType';
import { withRouter } from 'react-router-dom';
import BrokerCommisionHistory from '../Components/BrokerCommisionHistory/BrokerCommisionHistory';
import { convertServerDateTime } from '../Helpers/Converter';
import UpdatedByItem from '../Elements/UpdatedByItem';
import { Loader, PrimaryButton } from '@customer/react-relax';
import CustomerDropdown from '../Components/CustomerDropdown/CustomerDropdown';
import EditBrokerSalesCode from '../Components/EditBrokerSalesCode/EditBrokerSalesCode';
import { PresentationMode } from '../Enums/PresentationMode';

const CommissionPage = props => {
  const NO_CUSTOMER = '';
  // if user opens customer again after editing, but customer wasn't updated by server yet, add delay
  const RELOAD_CUTOMER_DELAY_MS = 2000;

  const [state, setFullState] = useState({
    salesCode: '',
    searchText: '',
    customersWhoHaveRates: [],
    defaultRate: null,
    customerRates: [],
    customerRatesSorted: false,
    filteredCustomerNumbers: null,
  });
  const [editExpanded, setEditExpanded] = useState(false);
  const [newCustomer, setNewCustomer] = useState(null);
  const [isNewSalesCode, setIsNewSalesCode] = useState(false);

  // currentlyExpandedCustomerNumber:
  //  null = default customer expanded
  //  'WP00...' = concrete customer expanded
  //  '' = no customer expanded
  const [
    currentlyExpandedCustomerNumber,
    setCurrentlyExpandedCustomerNumber,
  ] = useState(NO_CUSTOMER);

  const refs = {};

  const [loadingCounter, dispatchLoading] = useReducer((state, modifier) => {
    if (modifier === null) return 0;
    return state + modifier;
  }, 0);
  function addLoading() {
    dispatchLoading(1);
  }
  function subLoading() {
    dispatchLoading(-1);
  }

  function setState(newState) {
    setFullState(oldState => ({ ...oldState, ...newState }));
  }

  function updateBreadcrumbs(salesCode) {
    // If there is no navigation for Person page, add it.
    // It is for the case when this page opened by link
    if (!props.navigationList.some(x => x.id === 'person')) {
      var salesId = salesCode.substring(0, salesCode.indexOf('-'));
      props.navigationAppend('person', '/person/view/' + salesId);
    }
    props.navigationAppend('commission', props.location?.pathname);
  }

  // update breadcrumbs on startup and load data
  useEffect(init, []);
  function init() {
    let salesCode = props.match.params.salesCode;
    setState({ salesCode: salesCode });

    updateBreadcrumbs(salesCode);
    updateData(salesCode);
  }

  // This effect runs when uses press button "Add customer" from dropdown.
  // Here we creating blank rates object and also add customer as customer who have rates
  useEffect(() => {
    if (newCustomer) {
      var newCustomersWhoHaveRates = [...state.customersWhoHaveRates];
      if (
        newCustomersWhoHaveRates.every(x => x.number !== newCustomer.number)
      ) {
        newCustomersWhoHaveRates.push(newCustomer);
      }
      setState({
        newRate: {
          salesCode: state.salesCode,
          customerNumber: newCustomer.number,
          rates: [],
        },
        customersWhoHaveRates: newCustomersWhoHaveRates,
      });
    } else {
      var customersNumbersWhoHaveRates = state.customerRates.map(
        x => x.customerNumber
      );
      setState({
        newRate: null,
        customersWhoHaveRates: [
          ...state.customersWhoHaveRates.filter(x =>
            customersNumbersWhoHaveRates.includes(x.number)
          ),
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newCustomer]);

  // expand edit view, if broker with no rates is opened
  useEffect(() => {
    if (isNewSalesCode) {
      setCurrentlyExpandedCustomerNumber(undefined);
      setEditExpanded(true);
    }
  }, [isNewSalesCode]);

  // Load all the data for salescode
  function updateData(salesCode) {
    addLoading();
    ServiceClient.getCustomersWithDeviatedBrokerCost(
      salesCode,
      customers => {
        setState({ customersWhoHaveRates: customers });
        subLoading();
      },
      () => {
        subLoading();
      }
    );

    addLoading();
    ServiceClient.getAllCurrentBrokerRates(
      salesCode,
      rates => {
        rates = rates.map(rate => {
          return { ...rate, salesCode: rate.agentCode };
        });
        if (rates && rates.length) {
          let defaultRateIndex = rates.findIndex(r => !r.customerNumber);
          let defaultRate = rates.splice(defaultRateIndex, 1)[0];

          setState({
            defaultRate: defaultRate,
            customerRates: rates,
            customerRatesSorted: false,
          });
          setIsNewSalesCode(false);
        } else {
          let defaultRate = { salesCode: salesCode };

          setState({
            defaultRate: defaultRate,
            customerRates: [],
            customerRatesSorted: false,
          });
          setIsNewSalesCode(true);
        }
        subLoading();
      },
      () => {
        subLoading();
      }
    );
  }

  // order rows by inheritCommissionHandling asc then by customerNumber asc
  useEffect(() => {
    if (
      state.customerRatesSorted ||
      !state.customerRates?.length ||
      !state.customersWhoHaveRates?.length
    ) {
      return;
    }

    var sortedRates = state.customerRates.sort((a, b) => {
      if (a.inheritCommissionHandling !== b.inheritCommissionHandling)
        return a.inheritCommissionHandling ? 1 : -1;
      return (
        findCustomer(a.customerNumber)?.name?.localeCompare(
          findCustomer(b.customerNumber)?.name
        ) ?? 1
      );
    });
    setState({ customerRates: sortedRates, customerRatesSorted: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.customerRates,
    state.customersWhoHaveRates,
    state.customerRatesSorted,
  ]);

  // This function is a part of mechanism of updating rates in UI after we sent command to save rates to server.
  // It re-loads data just for one customer, but only if effective date on server is different from effective date on client.
  function loadSingleRateIfChanged(salesCode, customerNumber) {
    addLoading();
    ServiceClient.getBrokerCostRates(
      salesCode,
      customerNumber,
      false,
      rate => {
        rate = { ...rate, salesCode: rate.agentCode };
        var releaseLoading = true;
        if (!customerNumber) {
          if (state.defaultRate.effective !== rate.effective) {
            setState({ defaultRate: rate });
          }
        } else {
          var existingRateIdx = state.customerRates.findIndex(
            x => x.customerNumber === customerNumber
          );

          if (
            existingRateIdx === -1 ||
            state.customerRates[existingRateIdx].effective !== rate.effective
          ) {
            var newCustomerRates = [...state.customerRates];
            if (existingRateIdx === -1) {
              newCustomerRates.push(rate);
            } else {
              newCustomerRates[existingRateIdx] = rate;
            }
            setState({ customerRates: newCustomerRates });
          } else {
            setTimeout(() => subLoading(), RELOAD_CUTOMER_DELAY_MS);
            releaseLoading = false;
          }
        }
        if (releaseLoading) {
          subLoading();
        }
      },
      () => {
        subLoading();
      }
    );
  }

  // This function is a part of mechanism of updating rates in UI after we sent command to save rates to server.
  // Update default rates or rates for single customer in UI with value newRate
  function updateSingleRate(newRate) {
    if (!newRate.customerNumber) {
      setState({ defaultRate: { ...state.defaultRate, ...newRate } });
      return;
    }

    var oldRateIdx = state.customerRates.findIndex(
      x => x.customerNumber === newRate.customerNumber
    );
    var newRates = [...state.customerRates];
    if (oldRateIdx === -1) {
      newRates.push(newRate);
    } else {
      newRates[oldRateIdx] = {
        ...state.customerRates[oldRateIdx],
        ...newRate,
      };
    }

    var newCustomersWithRates = [...state.customersWhoHaveRates];
    if (
      state.customersWhoHaveRates.every(
        x => x.number !== newRate.customerNumber
      )
    ) {
      state.customersWhoHaveRates.push(newRate.customerNumber);
    }

    setState({
      customerRates: newRates,
      customersWhoHaveRates: newCustomersWithRates,
    });
  }

  function getRate(rateObject, rateType) {
    rateObject = rateOrDefaultRate(rateObject);
    return rateObject.rates?.find(x => x.rateType === rateType)?.rate ?? '-';
  }

  function findCustomer(customerNumber) {
    return state.customersWhoHaveRates.find(x => x.number === customerNumber);
  }

  function toggleExpandedCustomer(customerNumber) {
    if (currentlyExpandedCustomerNumber === customerNumber) {
      setCurrentlyExpandedCustomerNumber(NO_CUSTOMER);
    } else {
      setCurrentlyExpandedCustomerNumber(customerNumber);
    }
  }

  function renderBool(rate, boolFunc) {
    rate = rateOrDefaultRate(rate);
    let boolValue = boolFunc(rate);
    if (boolValue) {
      return (
        <div className="relax-icon relax-icon-ui-check relax-icon--sm"></div>
      );
    } else {
      return null;
    }
  }

  function rateOrDefaultRate(rate) {
    if (!state.defaultRate) return rate;
    if (rate.inheritCommissionHandling) return state.defaultRate;
    return rate;
  }

  function scrollToTarget(element, offset) {
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }

  return (
    <div className="commission-page">
      <h2>{state.salesCode}</h2>
      {!isNewSalesCode && (
        <div className="search-container relax-form-group">
          <CustomerDropdown
            customers={state.customersWhoHaveRates}
            useAddButton={true}
            showHeader={false}
            searchOnServer={true}
            onCustomerSelect={c => {
              if (
                state.customersWhoHaveRates.some(x => x.number === c.number)
              ) {
                setCurrentlyExpandedCustomerNumber(c.number);
                setTimeout(() => {
                  var ref = refs[c.number];
                  if (ref) {
                    scrollToTarget(ref, 100);
                  }
                }, 200);
              } else {
                setNewCustomer(c);
              }
            }}
            onAddClick={c => {
              setNewCustomer(c ?? {});
              setEditExpanded(true);
              setCurrentlyExpandedCustomerNumber(NO_CUSTOMER);
            }}
            onFilter={(filteredCustomers, text) => {
              if (!filteredCustomers) {
                setState({ filteredCustomerNumbers: null });
                return;
              }

              var customerNumberSet = {};
              filteredCustomers.forEach(
                x => (customerNumberSet[x.number] = true)
              );
              setState({ filteredCustomerNumbers: customerNumberSet });
            }}
          />
        </div>
      )}
      <div className="table-container">
        {!!loadingCounter && (
          <div className="loading-container">
            <Loader />
          </div>
        )}
        <table className="results-table relax-table relax-table--dense">
          <thead>
            <tr>
              <th className="width-8">
                {formatMessage(Messages.commissionPageWaypointNumber)}
              </th>
              <th className="width-auto">
                {formatMessage(Messages.commissionPageCustomer)}
              </th>
              <th className="width-8">
                {formatMessage(Messages.commissionPageOrganizationNumber)}
              </th>
              <th className="width-6">
                {formatMessage(Messages.commissionPageFeeAcceptance)}
              </th>
              <th className="width-6">
                {formatMessage(Messages.commissionPagePersonalAcceptance)}
              </th>
              <th className="width-6">
                {formatMessage(Messages.commissionPageMotorAcceptance)}
              </th>
              <th className="width-6">
                {formatMessage(Messages.commissionPageProperty)}
              </th>
              <th className="width-6">
                {formatMessage(Messages.commissionPageLiability)}
              </th>
              <th className="width-6">
                {formatMessage(Messages.commissionPagePerson)}
              </th>
              <th className="width-6">
                {formatMessage(Messages.commissionPageTransport)}
              </th>
              <th className="width-8">
                {formatMessage(Messages.commissionPageComprehensive)}
              </th>
              <th className="width-6">
                {formatMessage(Messages.commissionPagePersonalCommission)}
              </th>
              <th className="width-8">
                {formatMessage(Messages.commissionPageMotorCommission)}
              </th>
            </tr>
          </thead>
          <tbody>
            {[state.defaultRate]
              .concat([state.newRate])
              .concat(state.customerRates)
              .filter(
                x =>
                  x &&
                  (!x.customerNumber ||
                    !state.filteredCustomerNumbers ||
                    state.filteredCustomerNumbers[x.customerNumber])
              )
              .map(rate => {
                var customer = findCustomer(rate.customerNumber);
                var isNewRate = state.newRate && state.newRate === rate;
                var expanded =
                  currentlyExpandedCustomerNumber === rate.customerNumber;
                if (state.newRate && isNewRate) {
                  expanded = true;
                }
                var yellowRow = !!customer && rate.inheritCommissionHandling;
                if (expanded && rate.needReload && !loadingCounter) {
                  loadSingleRateIfChanged(rate.salesCode, rate.customerNumber);
                }
                return (
                  <>
                    <tr
                      className={`${yellowRow ? 'relax-is-warning' : ''}`}
                      key={`value-row-${rate.customerNumber}`}
                      onClick={() => {
                        toggleExpandedCustomer(rate.customerNumber);
                        setEditExpanded(false);
                        setNewCustomer(null);
                      }}
                      ref={el => {
                        refs[rate.customerNumber] =
                          el ?? refs[rate.customerNumber];
                      }}>
                      <td>{customer?.number ?? '-'}</td>
                      <td>{customer?.name ?? '-'}</td>
                      <td>{customer?.legalNumber ?? '-'}</td>
                      <td>{renderBool(rate, r => r.useBrokerCost)}</td>
                      <td>{renderBool(rate, r => r.usePersonalCommission)}</td>
                      <td>{renderBool(rate, r => r.useMotorCommission)}</td>
                      <td>{getRate(rate, BrokerCostType.Property)}</td>
                      <td>{getRate(rate, BrokerCostType.Liability)}</td>
                      <td>{getRate(rate, BrokerCostType.Person)}</td>
                      <td>{getRate(rate, BrokerCostType.Transport)}</td>
                      <td>{getRate(rate, BrokerCostType.Casco)}</td>
                      <td>
                        {getRate(rate, BrokerCostType.PersonalCommission)}
                      </td>
                      <td>
                        {getRate(rate, BrokerCostType.MotorCommission)}
                        <span className="expand-icon">
                          <div
                            className={`relax-icon ${
                              expanded
                                ? 'relax-icon-ui-chevron-up'
                                : 'relax-icon-ui-chevron-down'
                            } relax-icon--sm`}></div>
                        </span>
                      </td>
                    </tr>
                    {expanded && (
                      <tr
                        className="row-details"
                        key={`details-row-${rate.customerNumber}`}>
                        <td colSpan="13">
                          <div className="expanded-row-container">
                            <div className="expanded-row-subcontainer">
                              {!isNewRate && (
                                <table className="relax-table relax-table--dense current-details">
                                  <thead>
                                    <tr>
                                      <th>
                                        {formatMessage(
                                          Messages.commissionPageValidFrom
                                        )}
                                      </th>
                                      <th>
                                        {formatMessage(
                                          Messages.commissionPageValidTo
                                        )}
                                      </th>
                                      <th>
                                        {formatMessage(
                                          Messages.commissionPageUpdatedBy
                                        )}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        {rate.effective
                                          ? convertServerDateTime(
                                              rate.effective
                                            )
                                          : '-'}
                                      </td>
                                      <td>
                                        {rate.expires
                                          ? convertServerDateTime(rate.expires)
                                          : '-'}
                                      </td>
                                      <td>
                                        {rate.updatedBy ? (
                                          <UpdatedByItem
                                            updatedBy={rate.updatedBy}
                                          />
                                        ) : (
                                          '-'
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              )}

                              {!editExpanded && (
                                <PrimaryButton
                                  key="edit"
                                  className="edit-button"
                                  onClick={() => {
                                    setEditExpanded(true);
                                  }}>
                                  {formatMessage(Messages.edit)}
                                </PrimaryButton>
                              )}
                              {editExpanded && (
                                <EditBrokerSalesCode
                                  salesCode={rate.salesCode}
                                  customerNumber={rate.customerNumber}
                                  presentationMode={PresentationMode.Edit}
                                  allowChangeCustomer={!!newCustomer}
                                  isNewRate={isNewRate}
                                  intialCustomersList={state.customersWhoHaveRates.filter(
                                    x =>
                                      x.number &&
                                      x.number === state.newRate?.customerNumber
                                  )}
                                  excludedCustomerNumbers={state.customersWhoHaveRates
                                    .map(x => x?.number)
                                    .filter(
                                      x =>
                                        !x ||
                                        x !== state.newRate?.customerNumber
                                    )}
                                  onSave={newRate => {
                                    setEditExpanded(false);
                                    setCurrentlyExpandedCustomerNumber(
                                      NO_CUSTOMER
                                    );
                                    updateSingleRate(newRate);
                                    setNewCustomer(null);
                                    setIsNewSalesCode(false);
                                  }}
                                  onCancel={() => {
                                    setEditExpanded(false);
                                    setNewCustomer(null);
                                  }}
                                  onCustomerSelect={customer => {
                                    setNewCustomer(customer);
                                  }}
                                />
                              )}
                            </div>

                            {!isNewRate && (
                              <BrokerCommisionHistory
                                salesCode={rate.salesCode}
                                customerNumber={rate.customerNumber}
                              />
                            )}
                          </div>
                        </td>
                      </tr>
                    )}
                  </>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default withRouter(CommissionPage);
