import { ServiceClient } from '../../../../Services/ServiceClient';
import { addressActions, addressFormats } from '../../AddressConstants';

export const onSubmit = (
  event,
  agentCode,
  codePrefix,
  isModalOpenedOnTeamLevel,
  address,
  setAddressAction,
  format,
  dispatch
) => {
  event.preventDefault();

  if (isModalOpenedOnTeamLevel) {
    ServiceClient.createAddressForTeam(
      codePrefix,
      {
        ...address,
        countryId: address.country.id,
        format,
        // special case for POBox, create request differs from get response
        pOBoxFacilityName: address.facilityName,
        pOBoxNumber: address.number,
      },
      addressId => {
        handleSuccessfulCreateResult(
          { ...address, format, id: addressId },
          codePrefix,
          isModalOpenedOnTeamLevel,
          dispatch
        );
      }
    );
  } else {
    ServiceClient.createAddressForAgent(
      agentCode,
      {
        ...address,
        countryId: address.country.id,
        format,
        // special case for POBox, create request differs from get response
        pOBoxFacilityName: address.facilityName,
        pOBoxNumber: address.number,
      },
      addressId => {
        handleSuccessfulCreateResult(
          { ...address, format, id: addressId },
          codePrefix,
          isModalOpenedOnTeamLevel,
          dispatch
        );
      }
    );
  }
  setAddressAction(addressActions.View);
};

export const checkRequiredFields = (address, format) => {
  if (format === addressFormats.Street) {
    return checkCommonRequiredFields(address) && address.streetName?.length > 0;
  }
  if (format === addressFormats.Foreign) {
    return (
      address.addressLine1?.length > 0 && address.country?.name?.length > 0
    );
  }
  if (format === addressFormats.Freetext) {
    return checkCommonRequiredFields(address);
  }
  if (format === addressFormats.POBox) {
    return checkCommonRequiredFields(address) && address.number?.length > 0;
  }
  if (format === addressFormats.Unstructured) {
    return (
      checkCommonRequiredFields(address) && address.addressLine1?.length > 0
    );
  }
};

export const areAddressesEqual = (address, initialAddress) => {
  // for cases when user enters text in an empty field but then manually deletes the text
  const keys1 = Object.keys(address);
  for (let key of keys1) {
    if (address[key] === '') {
      delete address[key];
    }
  }

  if (address.format === addressFormats.Street) {
    return (
      checkCommonFieldsAreEqual(address, initialAddress) &&
      address.streetName === initialAddress.streetName &&
      address.houseNumber === initialAddress.houseNumber &&
      address.houseLetter === initialAddress.houseLetter &&
      address.apartmentNumber === initialAddress.apartmentNumber &&
      address.additionalAddress === initialAddress.additionalAddress
    );
  }
  if (address.format === addressFormats.POBox) {
    return (
      checkCommonFieldsAreEqual(address, initialAddress) &&
      address.number === initialAddress.number &&
      address.facilityName === initialAddress.facilityName
    );
  }
  if (address.format === addressFormats.Foreign) {
    return (
      checkCareOfFieldsAreEqual(address, initialAddress) &&
      address.addressLine1 === initialAddress.addressLine1 &&
      address.addressLine2 === initialAddress.addressLine2 &&
      address.addressLine3 === initialAddress.addressLine3 &&
      address.country?.name === initialAddress.country?.name &&
      address.city === initialAddress.city
    );
  }
  if (address.format === addressFormats.Unstructured) {
    return (
      checkCommonFieldsAreEqual(address, initialAddress) &&
      address.addressLine1 === initialAddress.addressLine1 &&
      address.addressLine2 === initialAddress.addressLine2 &&
      address.addressLine3 === initialAddress.addressLine3
    );
  }
  if (address.format === addressFormats.Freetext) {
    return (
      checkCommonFieldsAreEqual(address, initialAddress) &&
      address.additionalAddress === initialAddress.additionalAddress
    );
  }
};

export const getCountryByCountryCodeFromList = (code, list) => {
  return list.find(country => country.code === code);
};

const checkCareOfFieldsAreEqual = (address, initialAddress) => {
  return (
    address.careOf?.name === initialAddress.careOf?.name &&
    address.careOf?.prefix === initialAddress.careOf?.prefix
  );
};

const checkCommonFieldsAreEqual = (address, initialAddress) => {
  return (
    checkCareOfFieldsAreEqual(address, initialAddress) &&
    address.city === initialAddress.city &&
    address.postalCode === initialAddress.postalCode &&
    address.country?.name === initialAddress.country?.name
  );
};

const checkCommonRequiredFields = address => {
  return (
    address.city?.length > 0 &&
    address.postalCode.length > 0 &&
    address.country?.name?.length > 0
  );
};

const handleSuccessfulCreateResult = (
  address,
  codePrefix,
  isModalOpenedOnTeamLevel,
  dispatch
) => {
  ServiceClient.getAddressListForTeam(codePrefix, list => {
    dispatch({
      type: `${
        isModalOpenedOnTeamLevel
          ? 'setNewAddressForTeam'
          : 'setNewAddressForAgent'
      }`,
      address,
      list,
    });
  });
};
