import { ServiceClient } from '../../../../../../Services/ServiceClient';

export const updatePostalCode = (address, setAddress, postalCode) => {
  setAddress({ ...address, postalCode });

  if (!postalCode || postalCode.length < 4 || !address.country?.code) return;
  ServiceClient.searchPostalDistrict(
    postalCode,
    address.country?.code,
    data => {
      if (data.valid) {
        const wordCapitalizedPostalDistrictName = data.result
          .split(' ')
          .map(w => w.charAt(0).toUpperCase() + w.substr(1).toLowerCase())
          .join(' ');
        setAddress({
          ...address,
          city: wordCapitalizedPostalDistrictName,
          postalCode,
        });
      } else {
        setAddress({
          ...address,
          city: '',
          postalCode,
        });
      }
    }
  );
};
