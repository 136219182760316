import React, { memo } from 'react';
import './ForeignAddress.css';
import CountrySelector from '../CommonFields/CountrySelector';
import CareOfFields from '../CommonFields/CareOfFields/CareOfFields';
import { useAddressFormatContext } from '../../AddressFormatSwitcher/AddressFormatSwitcher';
import { formatMessage } from '../../../../../Localization/formatMessage';
import Messages from '../../../../../Localization/Messages';

const ForeignAddress = () => {
  const { getCommonFields } = useAddressFormatContext();

  return (
    <>
      <CareOfFields />

      <label>{formatMessage(Messages.addressComponentAddressLine1)}</label>
      <input
        className="foreign-address-field"
        type="text"
        placeholder="Required"
        {...getCommonFields('addressLine1')}
      />

      <label>{formatMessage(Messages.addressComponentAddressLine2)}</label>
      <input
        className="foreign-address-field"
        type="text"
        {...getCommonFields('addressLine2')}
      />

      <label>{formatMessage(Messages.addressComponentAddressLine3)}</label>
      <input
        className="foreign-address-field"
        type="text"
        {...getCommonFields('addressLine3')}
      />

      <label>{formatMessage(Messages.addressComponentCityLine)}</label>
      <input className="city-field" type="text" {...getCommonFields('city')} />

      <CountrySelector />
    </>
  );
};

export default memo(ForeignAddress);
