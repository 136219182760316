import React, { Component } from 'react';
import { SubHeading } from '@customer/react-relax';
import SearchComponent from '../Components/Search/SearchComponent';
import { withRouter, Link } from 'react-router-dom';
import { Loader } from '@customer/react-relax';
import queryString from 'query-string';
import { debounce } from 'lodash';
import { ServiceClient } from '../Services/ServiceClient';
import './SearchPage.css';
import Messages from '../Localization/Messages';
import { formatMessage } from '../Localization/formatMessage';
import { GlobalContext } from '../GlobalContext';
import { legalUnitSwitch } from '../Helpers/Converter';

class SearchPage extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    this.setQuery = debounce(this.setQuery, 500);
  }
  state = {
    query: '',
    searchInputValue: '',
    results: null,
    isLoading: false,
    isTyping: false,
    resultFromAD: null,
    legalUnit: null,
    businessArea: null,
  };
  static defaultProps = {
    minLimitCharacter: 3,
    maxLimitCharacter: 8,
  };

  componentDidMount() {
    this.onRouteChanged();
  }

  componentDidUpdate(prevProps) {
    if (
      (this.state.legalUnit &&
        this.state.legalUnit !== this.context.legalUnit) ||
      (this.state.businessArea &&
        this.state.businessArea !== this.context.businessArea)
    ) {
      //SomeOne Changed LU after search, need search again.
      this.updateUserPreferences();
      this.setQuery(this.state.query, false);
    }

    if (this.props.location !== prevProps.location) {
      // it means that someone did redirect to same page
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    const query = queryString.parse(this.props.location?.search).query ?? '';
    if (this.state.searchInputValue === '') {
      this.setState({
        searchInputValue: query,
      });
      this.setQuery(query);
    }
    this.props.navigationReset();
  }

  handleKeyPressedInSearch = event => {
    const keycode = event.keyCode;
    if (keycode !== 13) {
      return;
    }
    this.setQuery.flush();
  };

  showAddUserButton() {
    return (
      !this.state.isTyping &&
      !this.state.isLoading &&
      !this.state.resultFromAD &&
      this.state.query !== '' &&
      this.state.results?.length === 0
    );
  }

  isExactSalesIdInResultList = () => {
    return (
      this.state.results?.filter(
        x => x.salesId?.toUpperCase() === this.state.query?.toUpperCase()
      ).length === 1
    );
  };

  setQuery = (query, checkQueryEquality = true) => {
    if (
      !query ||
      query.length < this.props.minLimitCharacter ||
      query.length > this.props.maxLimitCharacter
    ) {
      this.setState({
        results: null,
        isLoading: false,
        query: '',
        resultFromAD: null,
      });
      return;
    }

    if (
      checkQueryEquality &&
      query.trim().toUpperCase() === this.state.query.trim().toUpperCase()
    ) {
      return;
    }

    this.updateUserPreferences();
    this.setState(
      {
        query: query,
        isLoading: true,
        resultFromAD: null,
      },
      () => {
        this.doSearch(this.state.query);
      }
    );
  };

  updateUserPreferences() {
    this.setState({
      legalUnit: this.context?.legalUnit,
      businessArea: this.context?.businessArea,
    });
  }

  setSearchInputValue = e => {
    this.setQuery(e.target.value);
    this.setState({
      searchInputValue: e.target.value,
      isTyping: true,
    });
  };

  doSearch = query => {
    if (typeof this._source != typeof undefined) {
      this._source.cancel('Operation canceled due to new request.');
    }

    this._source = ServiceClient.createCancellationToken();
    ServiceClient.findTeams(query, this._source, items => {
      items = this.mapFindTeamsEndpoint(items);
      this.setState({
        results: items.sort((a, b) =>
          this.sortExactMatch(a.salesId, a.salesId, query)
        ),
        isTyping: false,
      });

      const item = this.getFoundItemFromResult(items);

      if (item) {
        if (!this.isSalesIdEqualsToUserId(item)) {
          // If result found for current LU or BA, but user id not equal to sales id, then search name in AD
          this.searchAD(query);
        } else {
          //stop searching
          this.setState({ isLoading: false });
        }
      } else {
        //If result not found for current LU or BA check if exists in other LU or BA
        this.validateSalesIdUniqueness(query, () => {
          this.searchAD(query);
        });
      }

      this.setState({
        isLoading: false,
      });
      this.updateURL(query);
    });
  };

  getFoundItemFromResult(list) {
    const items = list ?? this.state.results;
    if (!items) {
      return;
    }
    if (this.state.query?.includes('-')) {
      for (const item of items) {
        const foundAgent = item?.salesCodes?.find(
          agent =>
            agent.salesCode.toUpperCase() === this.state.query.toUpperCase()
        );
        if (foundAgent) {
          return item;
        }
      }
      return null;
    } else {
      return items.find(
        person =>
          person.salesId?.toUpperCase() === this.state.query?.toUpperCase()
      );
    }
  }

  isSalesIdEqualsToUserId(item) {
    return item && item.userId === item.salesId;
  }

  isSalesIdShouldBeEditable() {
    const item = this.getFoundItemFromResult();

    return item && !this.isSalesIdEqualsToUserId(item);
  }

  validateSalesIdUniqueness(salesId, callBack) {
    if (this.cancellationSource) this.cancellationSource.cancel('New input');
    this.cancellationSource = ServiceClient.createCancellationToken();

    ServiceClient.getTeam(
      salesId,
      result => {
        result = this.mapGetTeamEndpoint(result);
        //Found in other LU or BA
        if (!this.isSalesIdEqualsToUserId(result)) {
          // If result found in other LU or BA and userid not equal to sales id, then search in AD, if equal don't search
          callBack();
        }
        this.setState({
          salesidExist: true,
          itemInAnotherLegalUnit: result,
          isLoading: !this.isSalesIdEqualsToUserId(result),
        });
      },
      () => {
        //Not found in other LU, search in AD
        this.setState({ salesidExist: false, itemInAnotherLegalUnit: null });
        callBack();
      }
    );
  }

  mapGetTeamEndpoint = result => {
    return {
      ...result,
      salesId: result.codePrefix,
      salesCodes: result?.agents.map(agent => ({
        ...agent,
        salesCode: agent.code,
      })),
    };
  };

  mapFindTeamsEndpoint = result => {
    return result?.map(item => ({
      id: item.id,
      salesId: item.codePrefix,
      userId: item.userId,
      name: item.name,
      salesCodes: item.agents?.map(agent => ({
        costCenter: { name: agent.costCenterName },
        salesCode: agent.code,
        countryName: agent.countryName,
        businessArea: agent.businessArea,
      })),
    }));
  };

  searchAD = query => {
    ServiceClient.getUserDetailsFromADbyUserID(query, result => {
      this.setState({
        resultFromAD: result,
      });
    });
  };

  sortExactMatch = (a, b, keyword) => {
    return a.toUpperCase() === keyword.toUpperCase()
      ? -1
      : b.toUpperCase() === keyword.toUpperCase()
      ? 1
      : 0;
  };

  updateURL = query => {
    const currentQuery = queryString.parse(this.props.location.search).query;
    if (currentQuery === query) {
      // check whether query changed. Important step, it allows to go forward in history
      return;
    }
    const url = queryString.stringify({
      query: query,
    });
    this.props.history.push(`?${url}`);
  };

  render() {
    return (
      <div>
        <div className="frontpage-logo"></div>
        <div className="relax-global-search relax-embedded relax-is-open search-container">
          <button className="relax-global-search-wp" type="button">
            <span className="relax-axe-sr-only">WP</span>
          </button>
          <div className="relax-margin-bottom--small">
            <SubHeading text={formatMessage(Messages.searchPageHeaderTitle)} />
          </div>
          <button className="relax-global-search-close" type="button">
            <span className="relax-axe-sr-only">Close</span>
          </button>
          <div className="relax-global-search-controller search-controller">
            <input
              className="mousetrap"
              id="searchInput"
              type="search"
              placeholder={formatMessage(Messages.searchPageInputPlaceholder)}
              onKeyDown={this.handleKeyPressedInSearch}
              autoComplete="off"
              value={this.state.searchInputValue}
              onChange={this.setSearchInputValue}
            />
          </div>
          {this.state.isLoading ? (
            <Loader />
          ) : (
            <SearchComponent
              maxLimitCharacter={this.props.maxLimitCharacter}
              result={this.state.results}
              query={this.state.query}
              searchInputValue={this.state.searchInputValue}
              resultFromAD={this.state.resultFromAD}
              legalUnit={legalUnitSwitch(this.state.legalUnit)}
              businessArea={this.state.businessArea}
              isSalesIdShouldBeEditable={this.isSalesIdShouldBeEditable()}
            />
          )}
          <Link
            id="addUserButton"
            className={
              'relax-button none-decoration ' +
              (this.showAddUserButton() ? 'show ' : 'hide ')
            }
            to={{
              pathname: 'person/add',
              state: {
                newSalesId: this.state.query,
                enableEditSalesId: this.isSalesIdShouldBeEditable(),
              },
            }}>
            {formatMessage(Messages.addUserBtn)}
          </Link>
        </div>
      </div>
    );
  }
}

export default withRouter(SearchPage);
