const getString = (configVariableName, defaultValue) => {
  return configVariableName.startsWith('#') && configVariableName.endsWith('#')
    ? defaultValue
    : configVariableName;
};

const config = {
  environment: getString('#{Environment}#', 'dev'),
  source: 'Waypoint Sales Agent App',
  components: {
    globalHeader: getString(
      '#{GlobalHeader.Url}#',
      'https://waypoint-customer-stest-global-header.azurewebsites.net/global-header.js'
    ),
  },
  apis: {
    Customer: getString(
      '#{Customer.Api}#',
      'https://waypoint-stest.ifint.biz:2001/api/customer/sales-agent-app'
      //'http://localhost/wip/api/customer/sales-agent-app'
    ),
    SalesAgent: '/SalesAgent.Api/salesAgentApp'
  },
};

export const storageKeys = {
  locale: 'last_used_locale',
  legalUnit: 'last_used_legal_unit',
  businessArea: 'business_Area',
};

export const defaultLanguageCode = 'en';

export default config;
