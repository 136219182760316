import React, { memo } from 'react';
import './UnstructuredAddress.css';
import AddressSearch from '../CommonFields/AddressSearch';
import CountrySelector from '../CommonFields/CountrySelector';
import { useAddressFormatContext } from '../../AddressFormatSwitcher';
import { useGlobalAddressContext } from '../../../AddressProvider';
import { addressActions } from '../../../AddressConstants';
import PostalFields from '../CommonFields/PostalFields';
import CareOfFields from '../CommonFields/CareOfFields/';
import { formatMessage } from '../../../../../Localization/formatMessage';
import Messages from '../../../../../Localization/Messages';

const UnstructuredAddress = () => {
  const { getCommonFields } = useAddressFormatContext();
  const { addressAction } = useGlobalAddressContext();

  return (
    <>
      {addressAction === addressActions.Create && <AddressSearch />}

      <CareOfFields />

      <label>{formatMessage(Messages.addressComponentAddressLine1)}</label>
      <input
        className="unstructured-address-field"
        type="text"
        {...getCommonFields('addressLine1')}
      />

      <label>{formatMessage(Messages.addressComponentAddressLine2)}</label>
      <input
        className="unstructured-address-field"
        type="text"
        {...getCommonFields('addressLine2')}
      />

      <label>{formatMessage(Messages.addressComponentAddressLine3)}</label>
      <input
        className="unstructured-address-field"
        type="text"
        {...getCommonFields('addressLine3')}
      />

      <CountrySelector />
      <PostalFields />
    </>
  );
};

export default memo(UnstructuredAddress);
